import type {Ref} from "vue";
import {joinURL, withProtocol, getQuery} from "ufo";

export const useServerRequestUrl: () => Ref<
  Omit<URL, "toString" | "toJSON" | "searchParams"> & {
    originPath: string;
    searchParams: ReturnType<typeof getQuery>;
  }
> = () => {
  const requestUrl: URL = useRequestURL({
    xForwardedHost: true,
    xForwardedProto: false,
  });

  return useState(() => {
    const {hash, host, hostname, password, pathname, port, search, username} =
      requestUrl;
    const forcedHttps = {
      hash,
      host,
      hostname,
      href: withProtocol(requestUrl.href, "https://"),
      password,
      pathname,
      port,
      search,
      searchParams: getQuery(requestUrl.href),
      username,
      origin: withProtocol(requestUrl.origin, "https://"),
      protocol: "https:",
    };
    return {
      ...forcedHttps,
      originPath: joinURL(forcedHttps.origin, forcedHttps.pathname),
    };
  });
};
