<script lang="ts" setup>
import {FetchError} from "ofetch";

const {title, error} = defineProps<{
  title?: string;
  error?: FetchError;
}>();

useHead({
  title: title ?? error?.statusMessage,
});
</script>

<template>
  <div class="content-grid has-logo-overlay-right min-h-full text-white">
    <div class="min-h-full flex flex-col justify-center items-center gap-4">
      <span class="text-4xl text-center">404</span>
      <span class="text-8xl text-center">{{ $t("404.title") }}</span>
      <NuxtLinkLocale to="/" class="trimm-button uppercase transition-all">
        {{ $t("404.button") }}
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
