import gsap from "gsap";
import {usePreferredReducedMotion} from "@vueuse/core";
import {randomFloatBetween} from "~/utils/randomNumber";

export type SceneType = "digitalDna" | "fabric" | "blob";

const booleanNumber = (value: string, equals: string) => {
  return value === equals ? 1 : 0;
};

let didRun = false;

export const getRandomColor = (): string => {
  const randomColor = Math.floor(
    Math.random() * Object.keys(AccentColors).length,
  );
  const color = Object.keys(AccentColors)[randomColor];
  //@ts-ignore
  return AccentColors[color].DEFAULT;
};

export const getRandomSceneType = (): SceneType => {
  const options: SceneType[] = ["digitalDna", "fabric", "blob"];
  return options[Math.floor(Math.random() * options.length)];
};

export const useSceneState = () => {
  if (!didRun) {
    didRun = true;
    setupSceneWatchers();
  }
  const SCENE_STATE_KEY = "SCENE_STATE" as const;
  const keyed = (s: string) => `${SCENE_STATE_KEY}_${s}`;

  const lightColor = useState<string | undefined>(
    keyed("lightColor"),
    () => AccentColors["pumpkin"].DEFAULT,
  );
  const type = useState<SceneType | undefined>(keyed("type"), () => "blob");

  const typeState = useState(keyed("types"), () => ({
    digitalDna: 0,
    fabric: 0,
    blob: 0,
  }));

  const tweenLight = useState(
    keyed("tweenLight"),
    () => lightColor.value ?? AccentColors["pumpkin"].DEFAULT,
  );

  const randomPosition = useState(keyed("randomPosition"), () => false);

  const tweenPositionDelta = useState<{centerBased: number; absolute: number}>(
    keyed("positionDelta"),
    () => ({centerBased: 0, absolute: 0}),
  );

  return {
    lightColor,
    type,
    typeState,
    tweenLight,
    randomPosition,
    tweenPositionDelta,
  };
};

const setupSceneWatchers = () => {
  const {
    lightColor,
    type,
    typeState,
    tweenLight,
    randomPosition,
    tweenPositionDelta,
  } = useSceneState();
  const preference = usePreferredReducedMotion();
  let lightTween: gsap.core.Tween | undefined;
  let typeTween: gsap.core.Tween | undefined;
  let positionTween: gsap.core.Tween | undefined;

  if (import.meta.client) {
    watch(
      type,
      (value, oldValue) => {
        typeTween?.kill();
        // Create a plain object for GSAP to modify
        const tweenTarget = {
          digitalDna: typeState.value.digitalDna,
          fabric: typeState.value.fabric,
          blob: typeState.value.blob,
        };
        value = value ?? "digitalDna";
        typeTween = gsap.to(tweenTarget, {
          digitalDna: booleanNumber(value, "digitalDna"),
          fabric: booleanNumber(value, "fabric"),
          blob: booleanNumber(value, "blob"),
          duration:
            preference.value === "reduce" || oldValue === undefined ? 0 : 1,
          onUpdate: () => {
            // Sync the animated values back to Vue refs
            typeState.value.digitalDna = tweenTarget.digitalDna;
            typeState.value.fabric = tweenTarget.fabric;
            typeState.value.blob = tweenTarget.blob;
          },
        });
      },
      {
        immediate: true,
      },
    );

    watch(
      randomPosition,
      (value, oldValue) => {
        positionTween?.kill();
        const centerBased = value ? randomFloatBetween(-0.5, 0.5) : 0;
        const absolute = value ? randomFloatBetween(0, 1) : 0;
        const d = tweenPositionDelta.value;
        positionTween = gsap.to(d, {
          centerBased,
          absolute,
          duration: oldValue === undefined ? 0 : 1,
          onUpdate: () => {
            tweenPositionDelta.value = d;
          },
        });
      },
      {
        immediate: true,
      },
    );

    watch(
      lightColor,
      (value, oldValue) => {
        lightTween?.kill();
        value = value ?? AccentColors["pumpkin"].DEFAULT;
        lightTween = gsap.to(tweenLight, {
          value: value,
          duration: oldValue === undefined ? 0 : 3,
        });
      },
      {
        immediate: true,
      },
    );
  }
};
