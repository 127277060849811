<script lang="ts" setup>
import type {Button} from "~/types/button";
import {NuxtLinkLocale, SvgoRightArrow, NuxtLink} from "#components";
import type {Component} from "vue";
import {isLinkTypeExternal} from "#magnolia-layer/types/api";

interface Props {
  button?: Button;
  icon?: Component | null;
}

const props = withDefaults(defineProps<Props>(), {
  icon: SvgoRightArrow,
  button: {
    type: "default",
  },
});

const resolvedLink = computed(() => {
  if (!props.button) return;
  return resolveLink(props.button);
});

const isExternal = computed(() => {
  return isLinkTypeExternal(props.button.link);
});
</script>

<template>
  <component
    :is="isExternal ? NuxtLink : NuxtLinkLocale"
    :href="resolvedLink?.link ?? '#'"
    :target="resolvedLink?.target"
    :class="'trimm-button ' + `trimm-button--${button.type}`">
    <span v-if="button.label" :class="{'pr-4': icon !== null}">
      {{ button.label }}
    </span>
    <component v-if="icon !== null" :is="icon" />
  </component>
</template>

<style lang="scss" scoped></style>
