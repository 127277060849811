<script setup lang="ts">
import {type NavigationItem} from "#magnolia-layer/types/api";

const props = defineProps<{
  item: NavigationItem;
  hoverUnderline?: boolean;
}>();
const resolvedName = computed(() => resolveName(props.item, props.item));
const resolvedItem = computed(() => resolveLink(props.item));
</script>

<template>
  <NuxtLinkLocale :class="hoverUnderline ? 'link-animation' : ''" :target="resolvedItem.target" :to="resolvedItem.link">
    {{ resolvedName }}
  </NuxtLinkLocale>
</template>

<style scoped lang="scss">
.link-animation {
  display: inline-block;
  text-decoration: none;
  position: relative;
  transition: all .125s ease-in-out;
  margin-bottom: 2px;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    border-bottom: solid 2px #fff;
    bottom: -4px;
    width: 0%;
    left: 0;
    transition: all .125s ease-in-out;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
}
</style>
